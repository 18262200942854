<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium" style="position: relative;">
        <el-form-item prop="parkingName">
          <el-input v-model="query.parkingName" placeholder="请输入-车场名称"/>
        </el-form-item>
        <el-form-item prop="regionName">
          <el-cascader v-model="query.regionName" :options="options" :props="{ checkStrictly: true }"
                       clearable placeholder="请选择-区域"></el-cascader>
        </el-form-item>
        <el-form-item prop="equipmentName">
          <el-input v-model="query.equipmentName" placeholder="请输入-设备SN"/>
        </el-form-item>
        <el-form-item prop="orderStatusPort">
          <el-select v-model="query.orderStatusPort" clearable placeholder="请选择-订单状态">
            <el-option label="预付" value="1"></el-option>
            <el-option label="启动中" value="2"></el-option>
            <el-option label="充电开始" value="3"></el-option>
            <el-option label="充电结束" value="4"></el-option>
            <el-option label="充电完成" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="orderIDName">
          <el-input v-model="query.orderIDName" placeholder="请输入-订单编号"/>
        </el-form-item>
        <el-form-item prop="berthName">
          <el-input v-model="query.berthName" placeholder="请输入-泊位编号"/>
        </el-form-item>
        <el-form-item prop="paymentStatusPort">
          <el-select v-model="query.paymentStatusPort" clearable placeholder="请选择-付款状态">
            <el-option label="未付款" value="1"></el-option>
            <el-option label="已付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="parkingTypeTime">
          <div class="xxd-input__wrap" style="width: 360px">
            <el-select
                v-model="query.parkingTypeTime"
                clearable
                placeholder="开始时间"
            >
              <el-option label="开始时间" value="1"></el-option>
              <el-option label="结束时间" value="2"></el-option>
              <el-option label="开始结束" value="3"></el-option>
            </el-select>
            <el-date-picker
                v-model="query.timeValues"
                :picker-options="pickerOptions"
                end-placeholder="结束日期"
                range-separator="~"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item prop="phoneName">
          <el-input v-model="query.phoneName" placeholder="请输入-手机号"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>


    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">

        <div class="content-item">
          <div class="title">当前查看：</div>
          <div class="text">{{ query.timeValues.join("~") || '0' }}</div>
        </div>
        <div class="content-item" style="margin-left: 20px;">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize || '0' }}</div>
        </div>
        <div class="content-item">
          <div class="title">订单金额：</div>
          <div class="text">{{ parseFloat(OrderAmount).toFixed(2) || '0' }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div>
        <el-table v-loading="isLoading" :data="dataList" border class="common-table" size="medium" style="width: 100%">
          <el-table-column label="车场" prop="parkingName" width="160px"></el-table-column>
          <el-table-column label="城区" prop="regionName" width="160px"></el-table-column>
          <el-table-column label="设备SN" prop="" width="160px"></el-table-column>
          <el-table-column label="用户手机号" prop="" width="160px"></el-table-column>
          <el-table-column label="订单状态" prop="" width="160px"></el-table-column>
          <el-table-column label="开始时间" prop="" width="160px"></el-table-column>
          <el-table-column label="结束时间" prop="" width="100px"></el-table-column>
          <el-table-column label="充电时长" prop="" width="100px"></el-table-column>
          <el-table-column label="订单金额" prop=""></el-table-column>
          <el-table-column label="付款状态" prop="" width="160px"></el-table-column>
          <el-table-column label="电费" prop="" width="160px"></el-table-column>
          <el-table-column label="服务费" prop="" width="160px"></el-table-column>
          <el-table-column label="优惠金额" prop="" width="160px"></el-table-column>
          <el-table-column label="实付金额" prop="" width="160px"></el-table-column>
          <el-table-column label="用电度数" prop="" width="160px"></el-table-column>
          <el-table-column label="订单编号" prop="" width="160px"></el-table-column>
          <el-table-column label="泊位编号" prop="" width="160px"></el-table-column>
          <el-table-column fixed="right" label="操作" prop="" width="160px"></el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize" :total="pagination.totalSize"
                       background layout="sizes, prev, pager, next, jumper"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/base';

export default {
  name: 'chongdianguanli',
  mixins: [BaseMixin],
  data() {
    return {
      badDebtMoney: 0.0,
      OrderAmount: 0.0,
      query: {
        parkingName: '',
        regionName: '',
        equipmentName: '',
        orderStatusPort: '',
        orderIDName: '',
        berthName: [],
        paymentStatusPort: '',
        parkingTypeTime: '1',
        // timeValues: ['2022-09-06', '2022-10-06'],
        timeValues: ['', ''],
        phoneName: ''
      },
      options: [],
      // dataList:[
      //   {
      //     parkingName:'',
      //     regionName:'',
      //   }
      // ]
    }
  },
  methods: {
    async search() {

    }
  }
}
</script>

<style lang="less" scoped>

</style>
